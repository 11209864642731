// Experiment keys for A/B Testing general settings/configurations.
/* eslint-disable max-len */

// Specifies the timeout/threshold value for all client web-api-client requests.
export const CLIENT_API_TIMEOUT = 'web.clientApi.timeout';

// Specifies the max number of retries for all client web-api-client requests.
export const CLIENT_API_RETRY_LIMIT = 'web.retryLogic.clientApi.limit';

// Specifies the timeout/threshold value for all stream loading requests in the web-tuner.
export const WEB_TUNER_STREAM_TIMEOUT = 'web.stream.timeout';

// Specifies the max number of retries for all stream loading requests in the web-tuner.
export const WEB_TUNER_STREAM_RETRY_LIMIT = 'web.retryLogic.stream.limit';

// Specifies the extra time allotted to shoutcast stream loading requests.
export const WEB_TUNER_SHOUTCAST_EXTRA_TIMEOUT =
  'web.retryLogic.stream.shoutcastExtraTime';

// Specifies a timeout that should be used to determine how long we should wait for the
// recurly library to load and emit a 'ready' event.
export const WEB_RECURLY_LIBRARY_TIMEOUT = 'web.recurly.library.timeout';

// Specifies whether to disable the server-side recurly product cache
export const DISABLE_RECURLY_PRODUCT_CACHE = 'disable.recurly.product.cache';

// Disable landing page product fetch
export const DISABLE_LANDING_PRODUCT_FETCH =
  'web.landing.recurly.fetch.disabled';

// Enable v65 recommended stations gallery on station profile pages
export const ENABLE_STATION_PROFILE_RECOMMENDED_CONTENT =
  'web.profile.station.recommended.content.enabled';

// Enable profile play button redirect to app store
export const ENABLE_PROFILE_PLAY_BUTTON_REDIRECT_TO_APP_STORE =
  'web.profile.playRedirect.enabled';

// Enables/disables error logging for the instream player
export const WEB_TUNER_INSTREAM_LOGGING_ENABLED =
  'ads.hls.advanced.trackingUrl.Debug.enabled';
/* eslint-enable max-len */

export const WEB_TUNER_AUTOPLAY_ENABLED = 'player.autoPlay.defaultValue';

// experiment for landing splash header
export const LANDING_SPLASH_HEADER_ENABLED = 'web.landing.splashHeader.enabled';
// experiment for landing splash header CTA
export const LANDING_SPLASH_HEADER_CTA = 'web.landing.splashHeader.cta';
// experiment for landing splash header CTA link
export const LANDING_SPLASH_HEADER_CTA_LINK =
  'web.landing.splashHeader.cta.link';
// experiment for landing splash header title
export const LANDING_SPLASH_HEADER_TITLE = 'web.landing.splashHeader.title';
// experiment for landing splash header subtitle
export const LANDING_SPLASH_HEADER_SUBTILE =
  'web.landing.splashHeader.subtitle';
// experiment for landing splash header logo
export const LANDING_SPLASH_HEADER_LOGO = 'web.landing.splashHeader.logo';
// experiment for landing splash header background
export const LANDING_SPLASH_HEADER_BACKGROUND =
  'web.landing.splashHeader.background';
export const LANDING_SPLASH_HEADER_MOBILE_BACKGROUND =
  'web.landing.splashHeader.mobile.background';

export const WEP_PROMPT_HEIGHT_OVERRIDE =
  'web.containers.webPrompt.height.override';

export const PLAYER_QUALIFIED_TUNE_SECONDS = 'player.qualifiedTune.seconds';

export const BROWSIES_DEFAULT_LANDING_PAGE_INDEX =
  'browsies.defaultLandingPageIndex';

export const WEB_MEDIA_PLAYER_ENABLED = 'player.webMediaPlayer.enabled';

export const ICY_METADATA_ENABLED = 'player.icyMetadata.enabled';
