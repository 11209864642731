import keyMirror from 'src/common/utils/keyMirror';

export const MAC_DESKTOP_OS = 'Macintosh';
export const WINDOWS_DESKTOP_OS = 'Windows';

export const desktopFuncNames = keyMirror(
  'quitDesktopAndInstallUpdate',
  'openSocialShare',
  'reloadPage',
  'openfacebookAuth',
  'openGoogleAuth',
  'setUpLocalizations',
  'setUpGeminiEventSender',
  'showOptOutPageMenuItem',
  'hideOptOutPageMenuItem',
  'showGdprSettingsMenuItem',
  'hideGdprSettingsMenuItem',
  'enableMenuPlayPause',
  'setSerial',
  'getSerial',
  'setPartnerId',
);
