export const DISCORD_SPLASH_PICTURE_IN_PICTURE =
  'discord.splash.picture.in.picture';
export const DISCORD_SPLASH_MAX_PARTICIPANTS = 'discord.splash.maxParticipants';
export const DISCORD_HOSTED_MODE_ACTIVATED = 'discord.hostedMode.activated';
export const DISCORD_SHARED_MODE_ACTIVATED = 'discord.sharedMode.activated';
export const DISCORD_GEORESTRICTIONS_INFO_BANNER_DEFAULT =
  'discord.georestriction.page.banner';
export const DISCORD_GEORESTRICTIONS_INFO_BANNER_NOW_PLAYING =
  'discord.georestriction.nowplaying.banner';
export const DISCORD_RICH_PRESENCE_DETAILS_BROWSING =
  'discord.richpresence.details.browsing';
export const DISCORD_RICH_PRESENCE_DETAILS_PLAYING =
  'discord.richpresence.details.playing';
export const DISCORD_GEORESTRICTION_GUIDE_ITEM_SINGLE_USER =
  'discord.georestriction.guideItem.singleUser';
export const DISCORD_GEORESTRICTION_GUIDE_ITEM_MULTIPLE_USERS =
  'discord.georestriction.guideItem.multipleUsers';
