// This category is used by Platform

const actions = {
  globalOptOut: 'settings.globalOptOut',
  ccpa: 'settings.ccpa',
  interestSelector: 'interestSelection',
  messageOfTheDay: 'motd',
  recentSearches: 'search.recents',
  linkAlexa: 'linkAlexa',
  gdpr: 'settings.gdpr',
  autoPlay: 'autoPlay',
  searchSuggestion: 'search.suggestion',
};

const labels = {
  show: 'show',
  dismiss: 'dismiss',
  save: 'save',
  remove: 'remove',
  tap: 'tap',
  click: 'click',
  clearSingle: 'clear.single',
  clearAll: 'clear.all',
  success: 'success',
  fail: 'fail',
  expand: 'expand',
  collapse: 'collapse',
  error: 'error',
  cancel: 'cancel',
  showMiniPlayer: 'miniplayer.show',
  playMiniPlayer: 'miniplayer.play',
  oneClick: 'oneClick',
};

const feature = {
  category: 'feature',
  actions,
  labels,
};

export default feature;
