// https://app.onetrust.com/cookies/categorizations
export const cookieCategoriesMap = {
  strictlyNecessary: 'C0001',
  performance: 'C0002',
  functional: 'C0003',
  targeting: 'C0004',
  socialMedia: 'C0005',
};

export const cookieCategories = Object.values(cookieCategoriesMap);
