import { isDiscordMode } from '../../utils/discord';
import { DEPLOYMENT_ENV, isProdEnv } from '../../utils/environment';
import vars from '../../vars';
import { shouldIgnoreEvent } from '../sentryFilters';

const deploymentEnv = isDiscordMode()
  ? `${DEPLOYMENT_ENV}-discord`
  : DEPLOYMENT_ENV;
// To avoid potential conflicts with source map resolution, we are appending `non-release` to the release version name
// for environments that don't bump their release version numbers, which could be confused for the production release.
const releaseVersion = ['development'].includes(DEPLOYMENT_ENV)
  ? `${RELEASE_VERSION}-non-release`
  : RELEASE_VERSION;

// TODO: Test removal of these explicit URLs to see if thirdPartyErrorFilterIntegration in src/client/bootstrap/sentry.js takes care of blocking these
export const ignoreThirdPartyUrls = [
  /cdn\.doubleverify\.com/,
  /c\.amazon-adsystem\.com/,
  /imasdk\.googleapis\.com/,
  /cdn\.cookielaw\.org/,
  /cdn\.flashtalking\.com/,
  /s\.yimg\.com/,
  /pdp-cdn\.retargetly\.com/,
  /securepubads\.g\.doubleclick\.net/,
  /static\.yieldmo\.com/,
  /s0\.2mdn\.net/,
  /j\.adlooxtracking\.com/,
];

// https://docs.sentry.io/platforms/javascript/configuration/
export const sentryConfig = {
  release: releaseVersion,
  environment: deploymentEnv,
  // Set to true for debugging during development. Also, uncomment __SENTRY_DEBUG__ env vars in webpack configs if
  // debugging prod builds.
  debug: false,
  sampleRate: isProdEnv() ? 0.5 : 1.0,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProdEnv() ? 0.001 : 1.0,
  maxBreadcrumbs: isProdEnv() ? 25 : 100,
  denyUrls: isProdEnv() ? ignoreThirdPartyUrls : [],
  beforeSend: (event, hint) => {
    if (shouldIgnoreEvent(event, hint)) {
      return null;
    }

    return event;
  },
};

export const sentryServerDsn = vars.get('SENTRY_DSN_SERVER');
export const sentryClientDsn = vars.get('SENTRY_DSN_CLIENT');
