/*
 * Error signifies that properties which are required to form a link (i.e. guide id, seo title)
 * are missing.
 */
export const MISSING_GUIDE_ITEM_LINK_INFO = 'MISSING_GUIDE_ITEM_LINK_INFO';

/*
 * Error signifies that redirect information could not be determined, most likely due to another
 * error (e.g. MISSING_GUIDE_ITEM_LINK_INFO).
 */
export const COULD_NOT_DETERMINE_REDIRECT = 'COULD_NOT_DETERMINE_REDIRECT';
