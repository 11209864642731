export default {
  category: 'unsubscribe',
  actions: {
    show: 'show',
    tap: 'tap',
    confirm: 'confirm',
  },
  labels: {
    month: 'monthly',
    annual: 'yearly',
  },
};
