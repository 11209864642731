export default {
  category: 'account',
  actions: {
    create: 'create',
    login: 'login',
    verify: 'verify',
    show: 'show',
    dismiss: 'dismiss',
    click: 'click',
    allow: 'allow',
    deny: 'deny',
    recaptchaScore: 'recaptchaScore',
    submit: 'submit',
  },
  labels: {
    email: 'email',
    facebook: 'facebook',
    google: 'google',
    apple: 'apple',
    signIn: 'signin',
    signUp: 'signup',
    verify: 'verify',
    partner: 'partner',
    confirmation: 'confirmation',
    linkAlexa: 'linkAlexa',
    pass: 'pass',
    fail: 'fail',
    success: 'success',
    forgotPassword: 'forgotPassword',
    discord: 'discord',
  },
};
