// These represent the strings for the hero area text lines.
export const HERO_LINE1 = 'coupon.hero.line1';
export const HERO_LINE2 = 'coupon.hero.line2';
export const HERO_LINE3 = 'coupon.hero.line3';

export const ENTER_CODE_UPPERCASE = 'coupon.enter.code.uppercase';
export const REDEEM_NOW = 'coupon.redeem.now';

export const FEATURELIST_HEADLINE = 'coupon.featureList.headline';
export const FEATURELIST = 'coupon.featureList';

export const DISCLAIMER = 'coupon.disclaimer';

// Localizations for error texts
export const ERROR_INVALID = 'coupon.error.invalid';
export const ERROR_SUBSCRIPTION_DISABLED = 'coupon.error.subscriptionDisabled';
export const ERROR_EXPIRED = 'coupon.error.expired';
export const ERROR_PREMIUM_USER = 'coupon.error.premiumUser';
export const ERROR_GENERAL = 'coupon.error.general';
export const ERROR_ALREADY_CLAIMED = 'coupon.error.already.claimed';
