export const fbStatus = {
  connected: 'connected',
};

export const oAuthSdkStates = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

// NOTE: the pairAuthFlow queryParam can also take a generic true value, designating the user is from a non-auto partner
export const pairAuthFlows = {
  pin: 'pin',
  qr: 'qr',
};
