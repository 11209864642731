import keyMirror from 'src/common/utils/keyMirror';

export const defaultLanguageCode = 'en-us';
export const fallbackAcceptLanguageSuffix = `${defaultLanguageCode};q=0.9`;

export const countryCodes = {
  US: 'US',
  AS: 'AS', // American Samoa
  GU: 'GU', // Guam
  MP: 'MP', // Northern Mariana Islands
  PR: 'PR', // Puerto Rico
  VI: 'VI', // Virgin Islands (U.S.)
};

export const countryStateCodes = {
  california: 'CA',
  washington: 'WA',
};

export const currencyCodes = {
  USD: 'USD',
};

export const countryCurrencyMap = {
  US: 'USD', // United States
  CA: 'CAD', // Canada
  GB: 'GBP', // United Kingdom
  UK: 'GBP', // United Kingdom
  AU: 'AUD', // Austraila
  MX: 'MXN', // Mexico
  DE: 'EUR', // Germany
  GR: 'EUR', // Greece
  IE: 'EUR', // Ireland
  BR: 'BRL', // Brazil
  CO: 'COP', // Colombia
  AR: 'ARS', // Argentina
  FR: 'EUR', // France
  ES: 'EUR', // Spain
  SI: 'EUR', // Slovenia
  SK: 'EUR', // Slovakia
  FI: 'EUR', // Finland
  PT: 'EUR', // Portugal
  EE: 'EUR', // Estonia
  NL: 'EUR', // Netherlands
  MT: 'EUR', // Malta
  CY: 'EUR', // Cyprus
  LU: 'EUR', // Luxembourg
  LT: 'EUR', // Lithuania
  LV: 'EUR', // Latvia
  BE: 'EUR', // Belgium
  IT: 'EUR', // Italy
  AT: 'EUR', // Austria
};

export const unitedStatesTerritories = keyMirror(
  countryCodes.AS,
  countryCodes.GU,
  countryCodes.PR,
  countryCodes.MP,
  countryCodes.VI,
);
