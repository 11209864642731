// [wells]: These are just config keys to source SKU values from RadioMill. May need
// to revisit if they should live in experiments...

export const MONTHLY_SUBSCRIPTION = 'web.premium.sku.defaultMonthly';
export const YEARLY_SUBSCRIPTION = 'web.premium.sku.defaultYearly';

export const MONTHLY_SUBSCRIPTION_RENEW_SKU =
  'web.subscription.monthly.renew.sku';
export const YEARLY_SUBSCRIPTION_RENEW_SKU =
  'web.subscription.yearly.renew.sku';

export const defaultSkuList = [YEARLY_SUBSCRIPTION, MONTHLY_SUBSCRIPTION];
