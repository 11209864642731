export default {
  category: 'mobileweb',
  actions: {
    tap: 'tap',
    show: 'show',
    close: 'close',
  },
  labels: {
    appDownload: 'app_download',
    branchBannerAppDownload: 'branch_banner_app_download',
    pairingSuccessPageAppDownload: 'pairing_success_page_app_download',
    playButtonAppDownload: 'play_button_app_download',
    playButtonAppStoreRedirect: 'play_button_app_store_redirect',
    tuneinRedirect: 'tunein.com_redirect',
    appDownloadDialog: 'app_download_dialog',
    nowPlayingDialog: 'now_playing_dialog',
    tuneinRedirectCornerClose: 'tunein.com_redirect_cornerclose',
  },
};
