// link location to desktop app
export const WEB_MAC_DESKTOP_HREF = 'web.macDesktop.href';
export const WEB_WINDOWS_DESKTOP_HREF = 'web.windowsDesktop.href';

// re-show download dialog after this number of days
export const WEB_MAC_DIALOG_SHOW_AFTER_X_DAYS =
  'web.dialog.macDesktop.showXDaysAfterSeen';
export const WEB_WINDOWS_DIALOG_SHOW_AFTER_X_DAYS =
  'web.dialog.windowsDesktop.showXDaysAfterSeen';

// land user on home instead of auth for both fresh install and re-open
export const MAC_DESKTOP_REDIRECT_ALL_TO_HOME =
  'macDesktop.redirectAllToHome.enabled';

// enable desktop Now Playing page
export const DESKTOP_NOW_PLAYING_PAGE_ENABLED = 'desktop.nowPlaying.enabled';

// Comma-delimited list of Desktop semver versions that should cause the Web app to display a manual update banner.
// Should be used when auto-updater is broken for any Desktop version.
export const DESKTOP_MANUAL_UPDATE_VERSIONS = 'desktop.manual.update.versions';
