export const PASSWORD = 'field.password';
export const PASSWORD_LABEL = 'field.label.password';
export const NAME = 'field.fullName';
export const NAME_LABEL = 'field.label.fullName';
export const EMAIL_ADDRESS = 'field.emailAddress';
export const EMAIL = 'field.email';
export const USERNAME = 'field.username';
export const MALE_LABEL = 'field.label.male';
export const FEMALE_LABEL = 'field.label.female';
export const NONBINARY_LABEL = 'field.label.nonbinary';
export const PREFERNOTTOSAY_LABEL = 'field.label.prefernottosay';
export const CONFIRM_PASSWORD = 'field.confirmPassword';

export const INVALID_EMAIL = 'validation.email.invalid';
export const INVALID_PASSWORD = 'validation.password.invalid';
export const PASSWORDS_DONT_MATCH = 'validation.passwords.dontMatch';
export const EMAIL_ALREADY_EXISTS = 'validation.email.alreadyExists';
export const FULL_NAME_TOO_LONG = 'validation.fullName.tooLong';
export const USERNAME_ALREADY_EXISTS = 'validation.username.alreadyExists';

export const NAME_REQUIRED = 'validation.fullName.isRequired';
export const EMAIL_REQUIRED = 'validation.email.isRequired';
export const USERNAME_REQUIRED = 'validation.username.isRequired';
export const PASSWORD_REQUIRED = 'validation.password.isRequired';
export const CONFIRM_PASSWORD_REQUIRED =
  'validation.confirmPassword.isRequired';

export const PRIVACY_POLICY = 'general.privacyPolicy';
export const TERMS_OF_SERVICE = 'general.termsOfService';
export const CANCEL = 'general.cancel';
export const MY_ACCOUNT = 'general.myaccount';
export const DISMISS = 'general.dismiss';
export const FOLLOW = 'general.follow';
export const UNFOLLOW = 'general.unfollow';
export const NEVERMIND = 'general.nevermind';

export const PREMIUM = 'general.premium';
export const FREE = 'general.free';
export const SPORTS = 'general.sports';
export const IHEART = 'general.iHeartRadio';
export const MUSIC = 'general.music';
export const NEWS = 'general.news';
export const NEWS_AND_TALK = 'general.newsAndTalk';
export const PODCASTS = 'general.podcasts';

export const SPORTS_REGULAR_CASE = 'general.sports.regular.case';
export const MUSIC_REGULAR_CASE = 'general.music.regular.case';
export const NEWS_REGULAR_CASE = 'general.news.regular.case';
export const NEWS_AND_TALK_REGULAR_CASE = 'general.newsAndTalk.regular.case';
export const PODCASTS_REGULAR_CASE = 'general.podcasts.regular.case';
export const AUDIOBOOKS_REGULAR_CASE = 'general.audiobooks.regular.case';

export const SPORTS_UPPERCASE = 'general.sports.uppercase';
export const MUSIC_UPPERCASE = 'general.music.uppercase';
export const NEWS_UPPERCASE = 'general.news.uppercase';
export const PODCASTS_UPPERCASE = 'general.podcasts.uppercase';
export const HOME = 'general.home';
export const LOCAL_RADIO = 'general.localRadio';
export const RECENTS = 'general.recents';
export const NO_RECENTS_TEXT = 'general.recents.empty';
export const TRENDING = 'general.trending';
export const TALK = 'general.talk';
export const BY_LOCATION = 'general.byLocation';
export const BY_LANGUAGE = 'general.byLanguage';
export const MAP_VIEW = 'general.mapView';
export const MAP_VIEW_SPLASHSCREEN_ERROR = 'mapView.splashScreen.error';
export const MAP_VIEW_EMPTY_RESULTS_TOAST = 'mapView.emptyResultsToast';
export const UPLOAD = 'general.upload';
export const AUDIOBOOKS = 'general.audiobooks';
export const FOR_YOU = 'general.forYou';

// uppercase words
export const GO_UPPERCASE = 'general.go.uppercase';
export const PREMIUM_UPPERCASE = 'general.premium.uppercase';
export const LISTEN_UPPERCASE = 'general.listen.uppercase';
export const EVERYWHERE_UPPERCASE = 'general.everywhere.uppercase';
export const DISCOVER_UPPERCASE = 'general.discover.uppercase';
export const MORE_UPPERCASE = 'general.more.uppercase';

// uppercase.period
export const SPORTS_RADIO_UPPERCASE_PERIOD =
  'general.sportsRadio.uppercase.period';
export const LIVE_SPORTS_UPPERCASE_PERIOD =
  'general.livesports.uppercase.period';
export const MUSIC_UPPERCASE_PERIOD = 'general.music.uppercase.period';
export const NEWS_UPPERCASE_PERIOD = 'general.news.uppercase.period';
export const PODCASTS_UPPERCASE_PERIOD = 'general.podcasts.uppercase.period';
export const AUDIOBOOKS_UPPERCASE_PERIOD =
  'general.audiobooks.uppercase.period';

export const LOGO_TITLE = 'general.logoTitle';

// Instruction to use to break a localization value into an array (useful for line breaking)
export const LINE_BREAK_INSTRUCTION = '{0}';
export const LINE_BREAK_INSTRUCTION_REGEX = /\{0}/g;
export const LINE_BREAK_INTERPOLATE = '{{linebreak/}}';
export const STATION_TITLE_INTERPOLATE = '{{stationTitle/}}';

export const TUNE_IN_BADGE_LOGO = 'general.asset.ti.badge.logo';

export const NEW_UPPERCASE = 'general.new.uppercase';
export const NEW = 'general.new';
