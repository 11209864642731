export const SUBSCRIPTION_ENABLED = 'subscription.enabled';

export const STRIPE_ENABLED = 'stripe.enabled';
export const RECURLY_COUPON_PREFIXES = 'recurly.coupon.prefixes';
export const STRIPE_ADDRESS_FIELD_ENABLED = 'stripe.address.field.enabled';
export const COUPON_CACHE_DISABLED = 'coupon.cache.disabled';
export const DEFAULT_SUBSCRIBE_PRODUCT_OVERRIDES =
  'default.subscribe.product.overrides';
export const DEFAULT_RENEW_PRODUCT_OVERRIDES =
  'default.renew.product.overrides';
export const SUBSCRIPTION_SUCCESS_PARTNER_CONFIG =
  'subscriptionSuccess.partner.config';
