// Constant values for ads related values

export const LOTAME_SEGMENT_LIMIT_DEFAULT = 40;

export const LOTAME_SEGMENT_LIMIT_KEY = 'ads.lotame.segmentLimit';

export const VIDEO_AD_DISABLE_SKIP_KEY =
  'ads.videoPreroll.disableSkipVideoPreroll';

export const DONT_SELL_MY_DATA_ENABLED = 'ads.dontSellMyData.enabled';

export const DATA_OPT_OUT_ENABLED = 'ads.dataOptOut.enabled';

export const ALLOW_IP_ADDRESS_SHARING = 'ads.passLocation.enabled';

export const AUDIO_PREROLL_INTERVAL_SECONDS = 'audioads.interval';

export const MIDROLL_ENABLED = 'ads.midroll.enabled';
export const MIDROLL_BREAKS_PER_SESSION = 'ads.acc.midrollBreaksPerSession';
export const MIDROLL_INTERVAL_SECONDS = 'ads.acc.timeBetweenRollsInSeconds';

export const VMAP_PREROLL_ENABLED = 'ads.preroll.vmap.enabled';

export const FREESTAR_ENABLED = 'ads.freestar.enabled';
export const BOTTOM_BANNER_ENABLED = 'ads.bottomBanner.enabled';

export const FREESTAR_PRIMIS_VIDEO_ENABLED = 'ads.freestar.primisVideo.enabled';
export const FREESTAR_PRIMIS_VIDEO_RELOAD_ON_ROUTE_ENABLED =
  'ads.freestar.primisVideo.reloadOnRoute.enabled';
