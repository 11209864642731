export const viewTypeAttributes = {
  fullPage: 'fp',
  dialog: 'dg',
};

export default {
  signIn: 'si',
  signUp: 'su',
  forgotPassword: 'fp',
  resetPassword: 'rp',
  logoutFailure: 'lf',
  verify: 'v',
};
