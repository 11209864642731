// Experiment keys for A/B Testing mobile web dialogs

// TUNE-9716: open install app dialog before playing content
export const WEB_OPEN_INSTALL_APP_DIALOG =
  'web.play.openInstallAppDialog.enabled';

// TUNE-9716: open install app dialog before playing content on first play
// This setting applies only if the above setting is also set to true
export const WEB_FIRST_PLAY_INSTALL_APP_DIALOG =
  'web.play.firstPlay.installAppDialog.enabled';

// TUNE-10547: Disable app install dialog on play for only listed guideIds
export const WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS =
  'web.play.openInstallAppDialog.disabled.guideIds';

// TUNE-9994: test UI features for improved version of the app install dialog
// show the player and hide the branch banner
export const WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER =
  'web.play.openInstallAppDialog.showPlayer.enabled';

// TUNE-11004 Text Me The App
export const TEXT_ME_THE_APP_ENABLED = 'web.text.me.the.app.enabled';
export const TEXT_ME_THE_APP_DISMISS_EXPIRY =
  'web.dialog.textMeTheApp.dismiss.expiry';

export const TEXT_ME_THE_APP_ENABLED_WINDOWS =
  'web.dialog.textMeTheApp.windows.enabled';
export const TEXT_ME_THE_APP_ENABLED_MAC =
  'web.dialog.textMeTheApp.mac.enabled';
export const TEXT_ME_THE_APP_ENABLED_MAC_DESKTOP_APP =
  'web.dialog.textMeTheApp.macDesktopApp.enabled';

// TUNE-10991 In App Message
export const IN_APP_MESSAGE_DIALOG_DISMISS_EXPIRY =
  'web.dialog.inAppMessage.dismiss.expiry';

// TUNE-11977 User Registration Dialog
export const WEB_SUBSEQUENT_LAUNCH_REGISTRATION_ENABLED =
  'web.premium.subsequentLaunchRegistration.enabled';
export const WEB_SUBSEQUENT_LAUNCH_REGISTRATION_SHOW_IN_DAYS =
  'web.premium.subsequentLaunchRegistration.showXDaysAfterSeen';

// TUNE-14611 Bypass Upsell Dialog
export const BYPASS_UPSELL_DIALOG_ENABLED =
  'web.premium.bypassUpsellDialog.enabled';
