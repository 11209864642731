import invert from 'lodash/invert';

export const actions = {
  show: 'show', // show some view/screen (e.g. upsell, signin/signup )
  tap: 'tap',
  fail: 'fail',
  success: 'success',
  close: 'close',
  cancel: 'cancel',
  buyMonthly: 'buy.monthly',
  buyYearly: 'buy.yearly',
  buyQuarterly: 'buy.quarterly',
  play: 'play',
  favorite: 'favorite',
  complete: 'complete',
  click: 'click',
  triggered: 'triggered',
  noDiscrepancy: 'no.discrepancy',
};

export const labels = {
  profileUpsellDialog: 'profile.upselldialog',
  directBilling: 'directbilling.responsiveupsell1',
  signin: 'signin',
  signup: 'signup',
  payment: 'payment',
  paypal: 'paypal',
  renew: 'renew',
  cta: 'cta',
  noThanks: 'noThanks',
  postalCodeInvalid: 'postalCodeInvalid',
  postalCodeMultiple: 'postalCodeMultiple',
  postalCodeNotFound: 'postalCodeNotFound',
  invalidPaymentInformation: 'invalid.payment.info',
  confirmation: 'confirmation',
  landingHeaderUpsell: 'landingpage.headerUpsell',
  landingCarouselUpsell: 'landingpage.carouselUpsell',
  landingTopUpsell: 'landingpage.topUpsell',
  landingGoPremiumUpsell: 'landingpage.goPremiumUpsell',
  landingFinalCtaUpsell: 'landingpage.finalCtaUpsell',
  homeUpsell: 'home.upsell', // radio/home
  headerUpsell: 'header.fullpageupsell',
  firstloadUpsell: 'firstload.upsell',
  subsequentLaunchUpsell: 'subsequentLaunch.upsell',
  sidebarUpsell: 'sidebar.upsell',
  leftsidenavUpsellDialog: 'leftsidenav.upselldialog',
  signUpUpsell: 'signup.upsell',
  fullPageUpsell: 'fullpage.upsell',
  pairingUpsell: 'pairing.upsell',
  profileUpsell: 'profile.upsell', // Deprecated
  homepageLanding: 'landingpage.fullpageupsell', // Deprecated
  leftsidenavUpsell: 'leftsidenav.fullpageupsell', // Deprecated
  interestSelectorUpsell: 'InterestSelection', // proper noun casing is intentional
  linkSubscription: 'linkSubscription',
  successPage: 'successPage',
  item: 'item',
  submit: 'submit',
  paymentForm: 'paymentForm',
  addressForm: 'addressForm',
  restore: 'restore',
  link: 'link',
};

export const invertedLabels = invert(labels);

const subscribe = {
  category: 'subscribe',
  actions,
  labels,
};

export default subscribe;
