const actions = {
  tap: 'tap',
  show: 'show',
  close: 'close',
  adblocker: 'adblocker',
  honeypot: 'honeypot',
};

const labels = {
  macDownloadPopup: 'mac_download_popup',
  macDownloadNav: 'mac_download_nav',
  macDownloadHeaderNav: 'mac_download_header_nav',
  windowsDownloadPopup: 'windows_download_popup',
  windowsDownloadNav: 'windows_download_nav',
  windowsDownloadHeaderNav: 'windows_download_header_nav',
  carousel: 'carousel',
  inAppMessage: 'in_app_message',
  textMeTheApp: 'text_me_the_app',
  registrationDialog: 'registration_dialog',
  changePlaybackRate: 'change_playback_rate',
  leftNav: 'leftNav',
  landingSplashHeader: 'landingSplashHeader',
};

const subLabels = {
  [labels.leftNav]: {
    search: 'search',
    signIn: 'signIn',
    signUp: 'signUp',
    favorites: 'favorites',
    account: 'account',
    privacy: 'privacy',
    logout: 'logout',
  },
};

const web = {
  category: 'web',
  actions,
  labels,
  subLabels,
};

export default web;
