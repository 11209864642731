// -home/landing page-

// words/phrases
export const LANDING_START_LISTENING = 'home.startlistening';
export const LANDING_LISTEN_NOW = 'home.listennow';
export const LANDING_SUGGEST_SEARCH_V2 = 'home.suggestsearch.noaudiobooks';

// copy
export const LANDING_UPSELL = 'home.upsell';
export const LANDING_LEAD_PARAGRAPH = 'home.lead.paragraph';
export const LANDING_LEAD_PARAGRAPH_V3 = 'home.lead.paragraph.v3';
export const LANDING_FINAL_CTA = 'home.finalcta';

export const LANDING_UPSELL_NEW_TITLE = 'home.upsell.new.title';
export const LANDING_UPSELL_NEW_SPORTS = 'home.upsell.new.sports';
export const LANDING_UPSELL_NEW_AUDIOBOOKS = 'home.upsell.new.audiobooks';
export const LANDING_UPSELL_NEW_NEWS = 'home.upsell.new.news';
export const LANDING_UPSELL_NEW_MUSIC = 'home.upsell.new.music';
export const LANDING_UPSELL_NEW_RADIO = 'home.upsell.new.radio';
export const LANDING_UPSELL_NEW_CTA = 'home.upsell.new.cta';
export const LANDING_UPSELL_NEW_DISCLAIMER = 'home.upsell.new.disclaimer';
export const LANDING_UPSELL_SUBTITLE = 'home.upsell.subtitle';
export const LANDING_UPSELL_TITLE = 'home.upsell.title';

export const LANDING_VERTICALS_SPORTS_DESCRIPTION =
  'home.verticals.sports.description';
export const LANDING_VERTICALS_MUSIC_DESCRIPTION =
  'home.verticals.music.description';
export const LANDING_VERTICALS_NEWS_DESCRIPTION =
  'home.verticals.news.description';
export const LANDING_VERTICALS_PODCASTS_DESCRIPTION =
  'home.verticals.podcasts.description';
export const LANDING_VERTICALS_AUDIOBOOKS_DESCRIPTION =
  'home.verticals.audiobooks.description';

export const LANDING_PREMIUM_HEADER = 'home.premium.header';
export const LANDING_PREMIUM_PRICING_LINE1 = 'home.premium.pricing.line1';
export const LANDING_PREMIUM_PRICING_LINE1_YEARLY =
  'home.premium.pricing.line1.yearly';
export const LANDING_PREMIUM_PRICING_LINE2 = 'home.premium.pricing.line2';
export const LANDING_PREMIUM_BULLET1 = 'home.premium.bullet1';
export const LANDING_PREMIUM_BULLET1POINT5 = 'home.premium.bullet1point5';
export const LANDING_PREMIUM_BULLET2 = 'home.premium.bullet2';
export const LANDING_PREMIUM_BULLET3 = 'home.premium.bullet3';
export const LANDING_PREMIUM_BULLET4 = 'home.premium.bullet4';
export const LANDING_PREMIUM_BULLET5 = 'home.premium.bullet5';
export const LANDING_PREMIUM_BULLET6 = 'home.premium.bullet6';

export const LANDING_DEVICES_HEADER = 'home.devices.header';
export const LANDING_DEVICES_PARAGRAPH = 'home.devices.paragraph.v2';

export const LANDING_DISCOVER_HEADER = 'home.discover.header';
export const LANDING_DISCOVER_PARAGRAPH = 'home.discover.paragraph.v2';

// alt text
export const LANDING_HERO_IMAGE_TEXT = 'home.heroImage.text';
export const LANDING_GET_IT_ON_GOOGLE = 'home.android.download.text';
export const LANDING_DOWNLOAD_ON_APPLE = 'home.apple.download.text';

// see carouselElements.json for landing carousel keys
