export const containerLayouts = {
  Gallery: 'Gallery',
  Carousel: 'Carousel',
  List: 'List',
  Locked: 'Locked',
  HeaderlessList: 'HeaderlessList',
  Matrix: 'Matrix',
  NumberedMatrix: 'NumberedMatrix',
  LinkMatrix: 'LinkMatrix',
  TileMatrix: 'TileMatrix',
};

// Note: More containers to support the reporting will be added.
export const containersWithReportingMap = {
  [containerLayouts.Gallery]: true,
  [containerLayouts.Carousel]: true,
  [containerLayouts.List]: true,
  [containerLayouts.Locked]: true,
  [containerLayouts.HeaderlessList]: true,
  [containerLayouts.Matrix]: true,
  [containerLayouts.NumberedMatrix]: true,
  [containerLayouts.LinkMatrix]: true,
  [containerLayouts.TileMatrix]: true,
};
