import breakpoints from '../styles/breakpoints';

// must be large to small
export const orderedAppBreakpoints = [
  breakpoints.XXLARGE,
  breakpoints.XLARGE,
  breakpoints.LARGE,
  breakpoints.MEDIUM,
  breakpoints.SMALL,
  breakpoints.XSMALL,
  breakpoints.BREAKPOINT_360,
  breakpoints.MIN,
];

export const WINDOW_RESIZE_DEBOUNCE_TIMEOUT = 1000 / 6; // Corresponds to 10 frames at 60 Hz.
