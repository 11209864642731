// Experiment/config keys for use for Search functionality

// Enabled/disables the live search results feature for the Search page.
export const LIVE_RESULTS_ENABLED = 'web.search.live.results.enabled';

// Sets the minimum number of characters required for a search query, in order for a search to be
// performed.
export const MINIMUM_CHARACTER_THRESHOLD = 'web.search.min.char.threshold';

// Sets the debounce delay for live searching, which assist in prevent the search API from being
// spammed too often.
export const LIVE_DEBOUNCE_DELAY = 'web.search.live.debounce.delay';

// Specifies a guideId that should be fetched in order to populate initial search results on search
// page.
export const FM_SEARCH_PREPOPULATE_GUIDE_ID = 'fm.search.prePopulate';

// Enabled/disables the live search with autocomplete suggestions feature for the Search page.
export const LIVE_AUTOCOMPLETE_SUGGESTIONS_ENABLED =
  'search.autocomplete.suggestions.enabled';
