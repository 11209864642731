const category = 'coupon';

const actions = {
  show: 'show',
  tap: 'tap',
  fail: 'fail',
  success: 'success',
};

export default {
  category,
  actions,
};
