const actions = {
  count: 'count',
  impression: 'impression',
  click: 'click',
  failure: 'failure',
  duplicatedcards: 'duplicatedcards',
};

const contentCard = {
  category: 'contentcard',
  actions,
};

export default contentCard;
