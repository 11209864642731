export const BRAZE_IN_APP_MESSAGE_ENABLED = 'web.braze.inAppMessage.enabled';
export const BRAZE_CONTENT_CARDS_ENABLED = 'web.braze.contentCards.enabled';
export const BRAZE_CONTENT_CARDS_REMOVE_DUPLICATES_ENABLED =
  'braze.contentCards.removeDuplicates.enabled';
export const BRAZE_CONTENT_CARDS_REFRESH_DELAY_SECONDS =
  'web.braze.contentCards.subscription.refresh.delay.seconds';
export const BRAZE_SESSION_TIMEOUT_IN_SECONDS = 'braze.sessionTimeoutSeconds';
export const CURRENT_EULA_VERSION = 'eulaVersion';
export const CURRENT_EULA_TYPE = 'eulaType';
export const TERMS_OF_SERVICE_LINK = 'policy.eula';
