import { cookieCategoriesMap } from './ads/consentCookieCategories';

// https://en.wikipedia.org/wiki/Year_2038_problem
export const permanentCookieExpirationDate = new Date(2038, 0, 1);
export const permanentCookieExpiration =
  permanentCookieExpirationDate.getTime();

// https://app.onetrust.com/cookies/categorizations
const { strictlyNecessary } = cookieCategoriesMap;

export const activeConsentCategoriesCookie = {
  name: 'activeCookieConsentCategories',
  consentCategory: strictlyNecessary,
};

export const authNameCookie = {
  name: 'tiac',
  consentCategory: strictlyNecessary,
};

export const upsellDialogCookie = {
  name: 'upsellDialog',
  value: 'treatyoself',
  consentCategory: strictlyNecessary,
};

export const subsequentLaunchUpsellDialogCookie = {
  name: 'subsequentLaunchUpsellDialog',
  consentCategory: strictlyNecessary,
};

export const firstOpenUpsellAttemptCookie = {
  name: 'firstOpenUpsellAttempt',
  consentCategory: strictlyNecessary,
};

export const mwLandingBypassCookie = {
  name: 'hideMobileOverlay',
  consentCategory: strictlyNecessary,
};

export const rtidCookie = {
  name: 'rtid',
  consentCategory: strictlyNecessary,
};

export const strAcctId = {
  name: 'strAcctId',
  consentCategory: strictlyNecessary,
};

export const dontShowDeleteDeviceConfirmation = {
  name: 'dsddc',
  consentCategory: strictlyNecessary,
};

export const firstVisitCookie = {
  name: 'firstVisit',
  consentCategory: strictlyNecessary,
};

export const isDesktopCookie = {
  name: 'isDesktop',
  consentCategory: strictlyNecessary,
};

export const desktopVersionCookie = {
  name: 'desktopVersion',
  consentCategory: strictlyNecessary,
};

export const desktopOSCookie = {
  name: 'desktopOS',
  consentCategory: strictlyNecessary,
};

export const hideMacDesktopDialogCookie = {
  name: 'hideMacDesktopDialog',
  consentCategory: strictlyNecessary,
};

export const hideWindowsDialogCookie = {
  name: 'hideWindowsDesktopDialog',
  consentCategory: strictlyNecessary,
};

export const textMeTheAppDialogCookie = {
  name: 'textMeTheApp',
  consentCategory: strictlyNecessary,
};

export const hidePopoutPlayerDialogCookie = {
  name: 'hidePopoutPlayerDialog',
  consentCategory: strictlyNecessary,
};

export const scheduledRegCookie = {
  name: 'scheduledRegCookie',
  consentCategory: strictlyNecessary,
};

export const inAppMessageDialogCookie = {
  name: 'inAppMessage',
  consentCategory: strictlyNecessary,
};

export const navBasedDialogManagerCookie = {
  name: 'navBasedDialogManager',
  consentCategory: strictlyNecessary,
};

export const ccpaOptOutCookie = {
  name: 'ccpaOptout',
  consentCategory: strictlyNecessary,
};

export const globalOptOutCookie = {
  name: 'globalOptOut',
  consentCategory: strictlyNecessary,
};

export const hasInteractedWithCcpaOptOutSettingsCookie = {
  name: 'hasInteractedWithOptOutSettings',
  consentCategory: strictlyNecessary,
};

export const showOptOutConfirmationDialogCookie = {
  name: 'showOptOutConfirmationDialog',
  consentCategory: strictlyNecessary,
};

export const mobileAppStoreRedirectCookie = {
  name: 'mobileAppStoreRedirect',
  consentCategory: strictlyNecessary,
};

export const showPairingSuccessPageCookie = {
  name: 'showPairingSuccessPage',
  consentCategory: strictlyNecessary,
};

// message of the day cookies
export const messageOfTheDayCookieDefault = {
  name: `messageOfTheDayDialog`,
  consentCategory: strictlyNecessary,
};

export const messageOfTheDayCookieMapView = {
  name: `messageOfTheDayDialog.mapView`,
  consentCategory: strictlyNecessary,
};

export const messageOfTheDayCookieBossRadio = {
  name: `messageOfTheDayDialog.bossRadio`,
  consentCategory: strictlyNecessary,
};

export const messageOfTheDayCookieAudiobooks = {
  name: `messageOfTheDayDialog.audiobooks`,
  consentCategory: strictlyNecessary,
};

export const messageOfTheDayCookieBig615 = {
  name: `messageOfTheDayDialog.big615`,
  consentCategory: strictlyNecessary,
};

export const boostTooltipDisplayCount = {
  name: `boostTooltipDisplayCount`,
  consentCategory: strictlyNecessary,
};

export const manualDiscordModeCookie = {
  name: `manualDiscordMode`,
  consentCategory: strictlyNecessary,
};

export const manualFordModeCookie = {
  name: `manualFordMode`,
  consentCategory: strictlyNecessary,
};

export const manualAutoModeCookie = {
  name: `manualAutoMode`,
  consentCategory: strictlyNecessary,
};
