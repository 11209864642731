/* eslint-disable max-len */

export const SIGN_UP_FOR_FREE_TRIAL_TEXT = 'auth.signup.for.free.trial.text';
export const SIGN_IN_AGREEMENT = 'auth.signin.agreement';
export const SIGN_UP_AGREEMENT = 'auth.signup.agreement';
export const AUTH_FLOW_AGREEMENT = 'auth.flow.agreement';
export const SIGN_UP_PROMPT = 'auth.signin.signupPrompt';
export const SIGN_IN_PROMPT = 'auth.signup.signinPrompt';
export const FORGOT_PASSWORD_INSTRUCTIONS = 'auth.forgotPassword.instructions';
export const RESET_PASSWORD_INSTRUCTIONS = 'auth.resetPassword.instructions';
export const GOOGLE = 'auth.oauth.google';
export const FACEBOOK = 'auth.oauth.facebook';
export const CONTINUE_WITH_FACEBOOK = 'auth.oauth.facebookButton';
export const CONTINUE_WITH_GOOGLE = 'auth.oauth.googleButton';
export const CONTINUE_WITH_APPLE = 'auth.oauth.appleButton';
export const DONT_HAVE_AN_ACCOUNT = 'auth.dont.have.an.account';
export const ALREADY_HAVE_AN_ACCOUNT = 'auth.already.have.an.account';
export const CREATE_AN_ACCOUNT = 'auth.create.an.account';
export const SIGN_IN = 'auth.signin';
export const SIGN_UP = 'auth.signup';
export const NEXT = 'auth.next';
export const YOU = 'auth.you';
export const DEVICE = 'auth.device';
export const SIGN_IN_SIGN_UP_TITLE = 'auth.signin.signup.title';
export const SIGN_IN_SIGN_UP_SUBTITLE = 'auth.signin.signup.subtitle';
export const SECTION_DIVIDER_TEXT = 'auth.section.divider.text';
export const AUTH_SIGN_UP_TITLE = 'auth.signup.title';
export const AUTH_SIGN_IN_TITLE = 'auth.signin.title';
export const MODAL_SECTION_DIVIDER_TEXT = 'auth.modal.section.divider.text';

// Page/view titles
export const SIGN_IN_OR_SIGN_UP_TITLE = 'auth.signin.or.signup.title';
export const SIGN_IN_OR_SIGN_UP_SUBTITLE = 'auth.subtitle';
export const VERIFY_SUBTITLE = 'auth.verify.subtitle';
export const FORGOT_PASSWORD_TITLE = 'auth.forgotPassword.title';
export const RESET_PASSWORD_TITLE = 'auth.resetPassword.title';

// Fields
export const REMEMBER_ME_LABEL = 'auth.rememberMe.label';
export const FORGOT_PASSWORD_LINK = 'auth.signin.forgotPassword.link';
export const FORGOT_PASSWORD_SUBMIT_BUTTON = 'auth.forgotPassword.submit.text';
export const BACK_TO_SIGN_IN = 'auth.forgotPassword.backToSignIn.link';
export const RESET_PASSWORD_SUBMIT_BUTTON = 'auth.resetPassword.submit.text';

export const BIRTH_YEAR = 'field.birthYear.v2';
export const BIRTH_YEAR_LABEL = 'field.birthYear.label';
export const USERNAME_EMAIL = 'field.usernameEmail';
export const USERNAME_EMAIL_LABEL = 'field.usernameEmail.label';

// Errors
export const SIGN_IN_FAILED = 'auth.signin.failed';
export const SIGN_UP_FAILED = 'auth.signup.failed';
export const LOGOUT_FAILED = 'auth.logout.failed';
export const RESET_PASSWORD_FAILED = 'auth.resetPassword.failed';
export const RESET_PASSWORD_IN_PROGRESS = 'auth.resetPassword.inProgress';
export const GENERAL_FAILURE = 'auth.general.failNotice';
export const BIRTH_YEAR_TOO_YOUNG = 'validation.birthYear.tooYoung16';
export const BIRTH_YEAR_REQUIRED = 'validation.birthYear.isRequired';
export const BIRTH_YEAR_INVALID_FORMAT = 'validation.birthYear.invalidFormat';
export const BIRTH_YEAR_INVALID = 'validation.birthYear.invalid';
export const OAUTH_GAPI_SIGNIN_ERROR = 'oauth.gapi.signin.error';
export const OAUTH_GAPI_SIGNUP_ERROR = 'oauth.gapi.signup.error';
export const OAUTH_FB_API_SIGNIN_ERROR = 'oauth.fb.api.signin.error';
export const OAUTH_FB_API_SIGNUP_ERROR = 'oauth.fb.api.signup.error';

// Notices
export const ACTION_REQUIRES_AUTH = 'auth.actionNeedsAuth.failNotice';
export const FORGOT_PASSWORD_SUCCESS = 'auth.forgotPassword.successNotice';
export const RESET_PASSWORD_SUCCESS = 'auth.resetPassword.successNotice';

// Validation
export const EMAIL_DOES_NOT_EXIST = 'validation.email.doesNotExist';
export const USERNAME_EMAIL_REQUIRED = 'validation.usernameEmail.isRequired';

export const NEED_HELP = 'auth.needHelp';

// Pairing
export const AUTH_PAIRING_FLOW_TI_CC_FLOW_HEADER =
  'auth.pairing.flow.ti.cc.flow.header';
export const AUTH_PAIRING_FLOW_TI_FOR_FREE_HEADER =
  'auth.pairing.flow.ti.for.free.header';
export const AUTH_PAIRING_PROMO_FINE_PRINT = 'auth.pairing.promo.fine.print';
export const AUTH_PAIRING_SUCCESS_HEADER = 'auth.pairing.success.header';
export const AUTH_PAIRING_SONOS_SUCCESS_HEADER =
  'auth.pairing.sonos.success.header';
export const AUTH_PAIRING_SONOS_ERROR_MESSAGE =
  'auth.pairing.sonos.error.message';
export const AUTH_PAIRING_SONOS_TRY_AGAIN = 'auth.pairing.sonos.error.tryAgain';
export const AUTH_PAIRING_GENERIC_FAILURE_HEADER =
  'auth.pairing.generic.failure.header';
export const AUTH_PAIRING_SUCCESS_ACCOUNT_CREATED =
  'auth.pairing.success.account.created';
export const AUTH_PAIRING_SUCCESS_ACCOUNT_LINKED =
  'auth.pairing.success.account.linked';
export const AUTH_PAIRING_SUCCESS_ACROSS_DEVICES =
  'auth.pairing.success.across.devices';
export const AUTH_PAIRING_SUCCESS_NO_CREDITCARD =
  'auth.pairing.success.no.creditcard';
export const AUTH_PAIRING_SUCCESS_NO_WORRY_TRIAL =
  'auth.pairing.success.no.worry.trial';
export const AUTH_PAIRING_SUCCESS_EXCLUSIVELY_FOR =
  'auth.pairing.success.exclusively.for';
export const AUTH_PAIRING_SUCCESS_MOBILE_PROMPT =
  'auth.pairing.success.mobile.prompt';
export const AUTH_PAIRING_SUCCESS_GET_MOBILE_APP =
  'auth.pairing.success.get.mobile.app';
export const AUTH_PAIRING_SUCCESS_GET_APP_BUTTON =
  'auth.pairing.success.get.app.button';
export const AUTH_PAIRING_SUCCESS_PROMO_FREEPREMIUM =
  'auth.pairing.success.promotion.freepremium';
export const AUTH_PAIRING_SUCCESS_FREEPREMIUM_ACTIVATED =
  'auth.pairing.success.freepremium.activated';
export const AUTH_PAIRING_FAILURE_DIALOG_MESSAGE =
  'auth.pairing.failure.dialog.message';

// Oauth Provider strings
export const OAUTH_DENY_BUTTON = 'oauth.deny.button';
export const OAUTH_ALLOW_BUTTON = 'oauth.allow.button';
export const OAUTH_NEXT_BUTTON = 'oauth.next.button';
export const OAUTH_DENY_LINK = 'oauth.deny.link';
export const OAUTH_ERROR_MSG_NO_REDIRECT = 'oauth.error.msg.no.redirect.text';
export const OAUTH_CONNECT_TITLE = 'oauth.would.like.to.connect.title';
export const OAUTH_CONNECT_PARTNER_TITLE =
  'oauth.partner.would.like.to.connect.title';
export const OAUTH_CONNECT_PARTNER_TITLE_PREMIUM =
  'oauth.would.you.like.partner.to.connect.title';
export const CONNECT_AND_SIGN_UP = 'connect.and.sign.up';
export const CONNECT_AND_LISTEN_FOR_FREE = 'connect.and.listen.for.free';

// Oauth Alexa Page
export const OAUTH_ALEXA_TITLE = 'oauth.alexa.you.need.to.be.subscribed.title';
export const OAUTH_ALEXA_LISTEN_ON_ALL_DEVICES =
  'oauth.alexa.listen.on.all.devices';
export const OAUTH_ALEXA_999_MONTH_PRICE = 'oauth.alexa.9.99.month.price';
export const OAUTH_ALEXA_START_TRIAL_TO_UNLOCK =
  'oauth.alexa.start.trial.to.unlock.with.website';
export const OAUTH_ALEXA_LISTEN_ON_ALEXA_DEVICES =
  'oauth.alexa.listen.on.only.alexa.devices';
export const OAUTH_ALEXA_LIMITED_399_MONTH_PRICE =
  'oauth.alexa.limited.3.99.month.price';
export const OAUTH_ALEXA_EXCLUSIVE_ALEXA_SUBSCRIPTION =
  'oauth.alexa.exclusive.alexa.subscription';
export const OAUTH_ALEXA_HAVING_ISSUES_WITH_ACCOUNT =
  'oauth.alexa.having.issues.with.account';
export const OAUTH_ALEXA_CONTACT_SUPPORT = 'oauth.alexa.contact.support';

// auth success
export const AUTH_SUCCESS_SIGN_IN = 'auth.success.sign.in';
export const AUTH_SUCCESS_SIGN_UP = 'auth.success.sign.up';

/* eslint-enable max-len */
