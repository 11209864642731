export const AUTH_OAUTHPREMIUMFLOW_ENABLED_PARTNERIDS =
  'web.auth.oauthPremiumFlow.enabled.partnerIds';
export const AUTH_OAUTHPREMIUMFLOW_ENABLED_PARTNERIDSKUS =
  'web.auth.oauthPremiumFlow.enabled.partnerIdSkus';
export const AUTH_OAUTHPREMIUMFLOW_ENABLED_PARTNERIDUPSELLDEFAULTS =
  'web.auth.oauthPremiumFlow.enabled.partnerIdUpsellDefaults';
export const AUTH_PAIRINGFLOW_SHOWUPSELLENABLED_PARTNERIDS =
  'web.auth.pairingFlow.showUpsellEnabled.partnerIds';
export const AUTH_EMAIL_SIGNUP_ENABLED = 'auth.email.signup.enabled';
export const AUTH_EMAIL_SIGNUP_RECAPTCHA_ENABLED =
  'auth.email.signup.recaptcha.enabled';
export const AUTH_EMAIL_SIGNUP_RECAPTCHA_SCORE_THRESHOLD =
  'auth.email.signup.recaptcha.score.threshold';
export const AUTH_EMAIL_SIGNIN_RECAPTCHA_ENABLED =
  'auth.email.signin.recaptcha.enabled';
export const AUTH_EMAIL_SIGNIN_RECAPTCHA_SCORE_THRESHOLD =
  'auth.email.signin.recaptcha.score.threshold';
