export const ROOT_PATH = '/';
export const HOME_BROWSE_PATH = '/radio/home/';
export const SPORTS_BROWSE_PATH = '/radio/sports/';
export const MUSIC_BROWSE_PATH = '/radio/music/';
export const NEWS_BROWSE_PATH = '/radio/Live-Stream-News-Radio-c57922/';
export const PODCASTS_BROWSE_PATH = '/podcasts/';
export const AUDIOBOOKS_BROWSE_PATH = '/audiobooks/';
export const PREMIUM_BROWSE_PATH = '/radio/premium/';
export const RECENTS_PATH = '/radio/recents/';
export const BY_LOCATION_PATH = '/radio/regions/';
export const BY_LANGUAGE_PATH = '/radio/languages/';
export const I_HEART_PATH = '/radio/Stream-iHeartRadio-c100005513/';

export const PAYMENT_PATH = '/payment/';
export const PAYMENT_COMPLETE_PATH = '/paymentcomplete/';
export const RENEW_PATH = '/renew/';
export const SUBSCRIBE_PATH = '/subscribe/';
export const SUBSCRIBE_PAIR_PATH = '/subscribepair/';
export const COUPON_PATH = '/coupon/';
export const UPDATE_PAYMENT_PATH = '/update/paymentmethod';

export const AUTHORIZE_PATH = '/authorize/';
export const AUTHORIZE_CONFIRM_PATH = '/authorize/confirm/';

export const LOGIN_PATH = '/login/';
export const ACCOUNT_NEW_RESET_PATH = '/account/new/reset/';
export const ACCOUNT_PATH = '/account/';
export const ACCOUNT_DEVICES_PATH = '/devices/';
export const ACCOUNT_PAYMENT_PATH = '/payment/';

export const USER_PATH = '/user/:userName/';
export const USER_FAVORITES_PATH = '/user/:userName/favorites';

export const SEARCH_PATH = '/search/';

export const MAP_VIEW = '/explorer/';

export const PREMIUM_OFFER = '/premiumoffer/';

export const PAIR_PATH = '/pair/';
export const AUTO_PATH = '/auto/';
export const PIN_CODE_AUTH_PATHS = [PAIR_PATH, AUTO_PATH];
