export const ACCOUNT_SETTINGS_PAGE_TITLE = 'account.pageTitle';
export const CHANGE_PASSWORD_FAILED = 'account.changePassword.failed';
export const CHANGE_PASSWORD_SUBMIT_SUCCESS =
  'account.changePassword.submit.success';
export const CHANGE_PASSWORD_SUBMIT_TEXT = 'account.changePassword.submit.text';
export const CHANGE_PASSWORD = 'account.changePassword.title';

export const NEW_PASSWORD = 'field.newPassword';
export const CONFIRM_NEW_PASSWORD = 'field.confirmNewPassword';

export const GENERAL_SUBMIT_TEXT = 'account.general.submit.text';

export const NAME_LABEL = 'field.label.fullName';
export const EMAIL_LABEL = 'field.label.email';
export const USERNAME_LABEL = 'field.label.username';
export const PASSWORD_LABEL = 'field.label.password';
export const FAVORITES_LABEL = 'field.label.favorites';
export const PUBLIC_PROFILE_LABEL = 'field.label.publicProfile';
export const PRIVATE_PROFILE_LABEL = 'field.label.privateProfile';
export const GENERAL_SUBMIT_SUCCESS = 'account.general.submit.success';

export const GENERAL_TABNAME = 'account.tabName.general';
export const DEVICES_TABNAME = 'account.tabName.devices';
export const SUBSCRIPTION_TABNAME = 'account.tabName.subscription';
export const GENERAL_PAGE_TITLE = 'account.pageTitle.general';
export const SUBSCRIPTION_PAGE_TITLE = 'account.pageTitle.subscription';
export const DEVICES_PAGE_TITLE = 'account.pageTitle.devices';

export const NEW_PASSWORD_IS_REQUIRED = 'validation.newPassword.isRequired';
export const CONFIRM_NEW_PASSWORD_IS_REQUIRED =
  'validation.confirmNewPassword.isRequired';

export const GENERAL_SUBMIT_FAILED = 'account.general.submit.failed';
export const CHANGE_PASSWORD_BUTTON = 'account.changePassword.button';
export const RESTORE_PURCHASE_BUTTON = 'account.restorePurchase.button';

export const RESTORE_PURCHASE_SUCCESS = 'account.restorePurchase.success';
export const RESTORE_PURCHASE_FAILED = 'account.restorePurchase.failed';
export const NO_SUBSCRIPTION_PURCHASE_FOUND =
  'account.no.subscription.purchase';
