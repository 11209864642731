import appConfig from '../../../common/config';
const { secureOrigin } = appConfig;

export const logging = {
  success: 'alexa.link.success',
  failure: 'alexa.link.failure',
  click: 'alexa.button.click',
  urlFetchFail: 'alexa.urlFetch.fail',
  buttons: {
    linkButton: 'link',
    successButton: 'success',
    retryButton: 'retry',
  },
};

// use local.tunein.com:3443 GEMINI_ORIGIN for local testing and host mapping to local ip
export const config = {
  redirectUrl: `${secureOrigin}/api/v1/oauth/alexa`,
  alexaAuthorizeUrl: `${secureOrigin}/api/v1/oauth/alexa/authorize`,
  secureOrigin,
  messageType: 'alexa.link.callback',
};

// https://developer.amazon.com/en-US/docs/alexa/account-linking/skill-activation-api.html#skillEnablement
export const linkStatusMap = {
  linked: 'LINKED',
  notLinked: 'NOT_LINKED',
};

export const skillStatusMap = {
  enabled: 'ENABLED',
  enabling: 'ENABLING',
  enablingFailed: 'ENABLING_FAILED',
  disabled: 'DISABLED',
  disabling: 'DISABLING',
  disablingFailed: 'DISABLING_FAILED',
  noAssociation: 'NO_ASSOCIATION',
};
