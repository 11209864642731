// Experiment keys for A/B Testing the Mobile Landing page (e.g. MobileWebEntry)

// TUNE-8870: Controls the text of the button. Default is a localized string (i.e. `Listen in App`)
export const CTA_BUTTON_TEXT = 'web.mobileLanding.cta.text';
// TUNE-11451: Controls the text of the button on premium browse/station pages.
export const CTA_BUTTON_TEXT_PREMIUM = 'web.mobileLanding.cta.text.premium';
// TUNE-9959: Controls the text color of the button.
export const CTA_BUTTON_TEXT_COLOR_HEX = 'web.mobileLanding.cta.text.hexColor';
// TUNE-9959: Controls the text color of the button for just the landing page
export const CTA_BUTTON_TEXT_COLOR_HEX_LANDINGONLY =
  'web.mobileLanding.landingPageOnly.cta.text.hexColor';
// TUNE-8968: Controls color of the mw landing branch link pill button.
export const CTA_BUTTON_COLOR_HEX = 'web.mobileLanding.cta.hexColor';
// TUNE-9951: Controls color of the mw landing branch link pill button for just the landing page.
export const CTA_BUTTON_COLOR_HEX_LANDINGONLY =
  'web.mobileLanding.landingPageOnly.cta.hexColor';

// TUNE-8967: URL for testable mobile web landing page background image
export const MW_LANDING_BACKGROUND_IMG_URL = 'web.mobileLanding.background.url';
// TUNE-11303: URL for testable mobile web landing page background image for just the landing page
export const MW_LANDING_BACKGROUND_IMG_URL_LANDINGONLY =
  'web.mobileLanding.landingPageOnly.background.url';
// TUNE-11451: Controls the guideIds considered premium browse/station pages.
export const MW_LANDING_PREMIUM_GUIDEIDS = 'web.mobileLanding.premium.guideIds';

// TUNE-8966: Show/hide the station subtitle
export const MW_LANDING_SUBTITLE_ENABLED = 'web.mobileLanding.subtitle.enabled';
// Show/hide the station description
export const MW_LANDING_DESCRIPTION_ENABLED =
  'web.mobileLanding.description.enabled';
// TUNE-8997: Show/hide value proposition text
export const MW_LANDING_VALUE_PROPOSITION_ENABLED =
  'web.mobileLanding.valueProposition.enabled';
// TUNE-8997: Value proposition text
export const MW_LANDING_VALUE_PROPOSITION_TEXT =
  'web.mobileLanding.valueProposition.text';
// TUNE-8997: Value proposition text
export const MW_LANDING_VALUE_PROPOSITION_ROOTGENRE_ENABLED =
  'web.mobileLanding.valueProposition.rootGenre.enabled';

// TUNE-9653: Enable mobile landing page
export const MW_LANDING_DISABLED = 'web.mobileLanding.disabled';
// TUNE-10547: Enable mobile landing page for only listed guideIds
export const MW_LANDING_DISABLED_GUIDEIDS =
  'web.mobileLanding.disabled.guideIds';
// TUNE-9746: Enable mobile landing page on only the landing/homepage
export const MW_LANDING_LANDINGONLY_DISABLED =
  'web.mobileLanding.landingPageOnly.disabled';
// TUNE-13807: For profile page, will disable mobile landing page on launch & show when user clicks play
export const MW_LANDING_DIRECT_TO_NOW_PLAYING =
  'web.mobileLanding.directToNowPlaying';

// TUNE-9878: experiment to show the x icon to close icon
export const MW_LANDING_BYPASS_CLOSEOPTION_X_ENABLED =
  'web.mobileLanding.bypass.closeOption.x.enabled';
// TUNE-9878: experiment to test the color of the close options
export const MW_LANDING_BYPASS_CLOSEOPTION_COLOR_HEX =
  'web.mobileLanding.bypass.closeOption.hexColor';

// TUNE-11221: experiment to enable play button
export const MW_LANDING_PLAYBUTTON_ENABLED =
  'web.mobileLanding.playButton.enabled';

// TUNE-11364
export const MW_LANDING_SHOW_AFTER_X_DAYS =
  'web.mobileLanding.showXDaysAfterBypass';
