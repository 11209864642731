import cssVariables from 'src/common/styles/variables';

/*
#7FCFEC Sky
#FEC25A Gold / Orange
#FD685F Coral
#DFC596 Tan
#14D8CC T-Sharp
*/
export const validColorsArr = [
  cssVariables['--sky'],
  cssVariables['--premium-gold-light'],
  cssVariables['--tunein-coral'],
  cssVariables['--tan'],
  cssVariables['--t-sharp'],
  cssVariables['--black'],
];

export const CAROUSEL_FETCH_SKIP_MSG = 'heroCarousel.fetched.false';
export const CAROUSEL_GUIDEID = 'landing';
export const CAROUSEL_UPSELL_GUIDEID = 'c100000260';
export const CAROUSEL_MAPVIEW_GUIDEID = 'c100006285';
