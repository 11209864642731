export default {
  category: 'play',
  actions: {
    start: 'start', // signifies when a tune occurs (manual or auto)
    successMs: 'successMs', // time elapsed between evaluating streams and stream success
    failMs: 'failMs', // time elapsed between evaluating streams and failure to play
    totalSuccessMs: 'totalSuccessMs', // total elapsed time between play intent and tune success
    totalFailMs: 'totalFailMs', // total elapsed time between play intent and tune failure
  },
  labels: {
    auto: 'auto', // signifies autoplay
    manual: 'manual', // signifies user initiated play
    preroll: 'preroll', // signifies time to tune with a preroll
    noPreroll: 'noroll', // signifies time to tune with no preroll
    next: 'next', // signifies play for a topic transition (next tune)
    adBlockerEnabled: 'adblocker.enabled', // signifies adblocker was enabled
    qualified: 'qualified', // gauges steady state ahead of first periodic report
  },
};
