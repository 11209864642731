// about EULA you can read there: https://tunein.atlassian.net/wiki/spaces/PROD/pages/2667249675/PRD+EULA+Consent+Framework
import viewTypes from '../../auth/viewTypes';

const eula = {
  category: 'eula',

  subcategory: {
    InAppConsent: {
      name: 'InAppConsent',

      action: {
        show: {
          name: 'show',
          getLabel: ({ loggedInValue }) => `loggedIn.${loggedInValue}`,
        },
        accept: {
          name: 'accept',
          getLabel: ({ eulaVersion, eulaType }) => `${eulaVersion}.${eulaType}`,
        },
      },
    },
    SignInConsent: {
      name: 'SignInConsent',

      action: {
        accept: {
          name: 'accept',
          getLabel: ({ eulaVersion, eulaType }) => `${eulaVersion}.${eulaType}`,
        },
      },
    },
    SignUpConsent: {
      name: 'SignUpConsent',

      action: {
        accept: {
          name: 'accept',
          getLabel: ({ eulaVersion, eulaType }) => `${eulaVersion}.${eulaType}`,
        },
      },
    },
  },
};

export default eula;
export const viewTypeToConsentCategoryMap = {
  [viewTypes.signIn]: eula.subcategory.SignInConsent.name,
  [viewTypes.signUp]: eula.subcategory.SignUpConsent.name,
};
