const actions = {
  show: 'show',
  enabled: 'enabled',
  disabled: 'disabled',
  optin: 'optin',
  optout: 'optout',
};

const labels = {
  swipe: 'swipe',
  tooltip: 'tooltip',
  button: 'button',
};

const boost = {
  category: 'boost',
  actions,
  labels,
};

export default boost;
