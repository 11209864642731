export const FAVORITES_PAGE_TITLE = 'favorites.pageTitle';
export const LOADING = 'favorites.loadingDotDotDot';
export const DEFAULT = 'favorites.default';
export const CREATE = 'favorites.create';
export const CANCEL = 'favorites.cancel';
export const REMOVE = 'favorites.remove';
export const EDIT = 'favorites.edit';
export const FAVORITE_ICON_LABEL = 'favorites.icon.label';
export const MOVE_TO = 'favorites.moveToDotDotDot';
export const MAKE_DEFAULT = 'favorites.makeDefault';
export const DELETE_FOLDER = 'favorites.deleteFolder';
export const DELETE_CONTENT = 'favorites.deleteContent';
export const CONFIRM_DELETE = 'favorites.confirmDelete';
export const ADD_NEW_FOLDER = 'favorites.addNewFolder';
export const PROMPT_UNFAV = 'favorites.promptUnfavorite';
export const FOLDER_NAME = 'favorites.folderName';
export const ADDED_TO_FAVORITES = 'favorites.add.default';
export const EPISODE_ADDED_TO_FAVORITES = 'favorites.add.episode';
export const PODCAST_ADDED_TO_FAVORITES = 'favorites.add.podcast';
export const STATION_ADDED_TO_FAVORITES = 'favorites.add.station';
export const ADD_TO_FAVORITES_ERROR = 'favorites.add.default.error';
export const EPISODE_REMOVED_FROM_FAVORITES = 'favorites.remove.episode';
export const PODCAST_REMOVED_FROM_FAVORITES = 'favorites.remove.podcast';
export const STATION_REMOVED_FROM_FAVORITES = 'favorites.remove.station';
export const REMOVED_FROM_FAVORITES = 'favorites.remove.default';
export const REMOVE_FROM_FAVORITES_ERROR = 'favorites.remove.default.error';
export const DEFAULT_FAVORITE_PAGE_PROMPT_INSTRUCTIONS =
  'favorites.default.prompt.instructions';
