export const httpsBaseUrl = 'https://tunein.com/';

export const TOS = `${httpsBaseUrl}policies/`;
export const PRIVACY = `${httpsBaseUrl}policies/privacy/`;
export const GERMANY_PRIVACY =
  'https://privacy.tunein.com/policies/?name=impressum';
export const CCPA = `${httpsBaseUrl}policies/ccpa/`;
export const PREMIUM_PATH = `${httpsBaseUrl}premium/`;
export const HELP_URL = 'https://help.tunein.com';
export const ONAIR_ADD_STATION_FORM_URL =
  'https://broadcasters.tunein.com/stations/add';
/*
The plan is for https://help.tunein.com/contact to change to what’s on
https://help.tunein.com/contact/user-BJFGATB_G once desktop is released.
For now all the web contact urls will be https://help.tunein.com/contact/user-BJFGATB_G.
We will revert back to https://help.tunein.com/contact once the desktop app is released.
 */
export const HELP_CONTACT_URL = `${HELP_URL}/contact/user-BJFGATB_G`;

export const HELP_CHROME80 = `${HELP_URL}/why-can-t-i-play-my-station-on-tunein-com-anymore--Sk_MFlRmU`;

export const RADIOMILL_CMS_ASSET_URL =
  'https://cdn-cms.tunein.com/gemini/backgrounds/';

export const RADIOMILL_CMS_CUSTOM_ASSET_URL =
  'https://cdn-cms.tunein.com/gemini/custom-source/';

export const WASHINGTON_MY_HEALTH_MY_DATA_ACT_URL =
  'https://privacy.tunein.com/policies/en/?name=privacy-policy#health-data';

export const HOW_TUNEIN_WORKS =
  'https://privacy.tunein.com/policies/?name=how-tune-in-works';
