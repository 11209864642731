/* eslint-disable max-len */

// settings/subscription/views/active
export const CANCEL_SUBSCRIPTION_LINK = 'subscription.cancel.link';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'subscription.cancel.success';
export const CANCEL_FAILED = 'subscription.cancel.failed';
export const ACTIVE_CARD_CHARGED_PRICE_ON_DATE =
  'subscription.charged.price.on.date';
export const ACTIVE_PAYMENT_CHARGED_PRICE_ON_DATE =
  'subscription.payment.charged.price.on.date';
export const ACTIVE_AUTO_RENEW_MONTHLY_COPY =
  'subscription.auto.renew.monthly.price.on.date';
export const ACTIVE_AUTO_RENEW_YEARLY_COPY =
  'subscription.auto.renew.yearly.price.on.date';
export const ACTIVE_NO_BILLING_MONTHLY_COPY =
  'subscription.active.nobilling.monthly.copy';
export const ACTIVE_NO_BILLING_YEARLY_COPY =
  'subscription.active.nobilling.yearly.copy';
export const UPDATE_PAYMENT_METHOD_LINK =
  'subscription.updatePaymentMethod.link';
export const ADD_PAYMENT_METHOD_LINK = 'subscription.add.paymentmethod.link';
export const ACTIVE_AUTO_RENEW_YEARLY_WITH_DISCOUNT_DURATION_ONCE_COPY =
  'subscription.auto.renew.yearly.with.discount.duration.once.price.on.date';
export const ACTIVE_AUTO_RENEW_MONTHLY_WITH_DISCOUNT_DURATION_ONCE_COPY =
  'subscription.auto.renew.monthly.with.discount.duration.once.price.on.date';
export const ACTIVE_AUTO_RENEW_MONTHLY_WITH_DISCOUNT_DURATION_IN_MONTHS_COPY =
  'subscription.auto.renew.monthly.with.discount.duration.in.months.price.on.date';

// cancel confirmation dialog
export const COMPLETE_CANCELLATION_BUTTON =
  'subscription.complete.cancellation.text';
export const GO_BACK_BUTTON = 'subscription.cancel.goback.text';
export const RESTART_COPY = 'subscription.cancel.restart.text';
export const CANCEL_NOTICE = 'subscription.cancel.notice.with.date';

// settings/subscription/views/cancelled
export const SUBSCRIPTION_ENDED_COPY = 'subscription.ended.with.date';
export const SUBSCRIPTION_WILL_END_COPY = 'subscription.will.end.with.date';
export const SUBSCRIPTION_CAN_RENEW_COPY = 'subscription.renew.with.price';
export const RENEW_SUBSCRIPTION_BUTTON = 'subscription.renew.text';
export const RENEW_SUBSCRIPTION_YOUR_SUBSCRIPTION =
  'subscription.renew.your.subscription';
export const RENEW_SUBSCRIPTION_SUBTITLE = 'subscription.renew.subtitle';
export const RENEW_SUBSCRIPTION_SUBTITLE_MONTH =
  'subscription.renew.subtitle.month';
export const RENEW_SUBSCRIPTION_SUBTITLE_YEAR =
  'subscription.renew.subtitle.year';

// settings/subscription/titles
export const PAYMENT_DETAILS = 'subscription.payment.details';

// subscription/External
export const APPLE_EXTERNAL_SUBSCRIPTION_STATUS =
  'subscription.external.status.apple';
export const GOOGLE_EXTERNAL_SUBSCRIPTION_STATUS =
  'subscription.external.status.google';
export const OTHER_EXTERNAL_SUBSCRIPTION_STATUS =
  'subscription.external.status.other';

// subscription/Subscribe
export const SUBSCRIBE_SUBMIT_BUTTON = 'subscribe.submit.button';
export const SUBSCRIBE_YEARLY_RADIO_TITLE_LABEL =
  'subscribe.yearly.radio.title.label';
export const SUBSCRIBE_MONTHLY_RADIO_TITLE_LABEL =
  'subscribe.monthly.radio.title.label';
export const SUBSCRIBE_QUARTERLY_RADIO_TITLE_LABEL =
  'subscribe.quarterly.radio.title.label';
export const SUBSCRIBE_MONTHLY_SETUPFEE_RADIO_TITLE_LABEL =
  'subscribe.monthly.setupFee.radio.title.label';
export const SUBSCRIBE_NON_TRIAL_TITLE = 'subscribe.non.trial.title';
export const RENEW_SUBMIT_BUTTON = 'renew.submit.button';
export const SUBSCRIBE_GO_PREMIUM_NOW_LABEL = 'subscribe.go.premium.now.label';

export const SUBSCRIBE_WITH_DAYS_TRIAL_RADIO_SUBTITLE_LABEL =
  'subscribe.with.days.trial.subtitle.label';
export const SUBSCRIBE_WITH_MONTHS_TRIAL_RADIO_SUBTITLE_LABEL =
  'subscribe.with.months.trial.subtitle.label';

export const SUBSCRIBE_WITH_SPECIAL_TRIAL_RADIO_SUBTITLE_LABEL =
  'subscribe.with.special.trial.subtitle.label';

export const SUBSCRIBE_DEFAULT_PRODUCT_DETAILS =
  'subscribe.product.details.default';
export const SUBSCRIBE_MONTHLY_PRODUCT_DETAILS =
  'subscribe.product.details.monthly';
export const SUBSCRIBE_NO_TRIAL_PRODUCT_DETAILS =
  'subscribe.product.details.no.trial';
export const SUBSCRIBE_NO_TRIAL_WITH_INTRO_PRICING_DETAILS_YEARLY =
  'subscribe.product.details.yearly.no.trial.with.intro';
export const SUBSCRIBE_NO_TRIAL_WITH_INTRO_PRICING_DETAILS_MONTHLY =
  'subscribe.product.details.monthly.no.trial.with.intro';
export const SUBSCRIBE_MONTHLY_PRODUCT_DETAILS_SETUPFEE =
  'subscribe.product.details.monthly.setupFee';
export const SUBSCRIBE_YEARLY_PRODUCT_WITH_DISCOUNT_DURATION_ONCE_DETAILS =
  'subscribe.yearly.product.with.discount.duration.once.details';
export const SUBSCRIBE_MONTHLY_PRODUCT_WITH_DISCOUNT_DURATION_ONCE_DETAILS =
  'subscribe.monthly.product.with.discount.duration.once.details';
export const SUBSCRIBE_MONTHLY_PRODUCT_WITH_DISCOUNT_DURATION_IN_MONTHS_DETAILS =
  'subscribe.monthly.product.with.discount.duration.in.months.details';
// subscription unavailable
export const SUBSCRIBE_UNAVAILABLE_IN_REGION =
  'subscribe.unavailable.in.region';
export const SUBSCRIBE_UNAVAILABLE_CONTINUE_BROWSING =
  'subscribe.unavailable.continue.browsing';

// subscription payment disclaimer/agreement
export const SUBSCRIBE_AGREEMENT = 'subscribe.agreement';
export const SUBSCRIBE_AUTO_RENEW_COPY =
  'subscribe.auto.renew.copy.with.linebreak';
export const LEARN_MORE_TEXT = 'learn.more.text';
export const SUBSCRIBE_TERMS_AGREEMENT = 'subscribe.terms.agreement';
export const SUBSCRIBE_TERMS_AGREEMENT_ERROR =
  'subscribe.terms.agreement.error';

// payment form
export const SUBSCRIBE_PAYMENT_FORM_FIRST_NAME =
  'subscribe.paymentForm.firstName';
export const SUBSCRIBE_PAYMENT_FORM_LAST_NAME =
  'subscribe.paymentForm.lastName';
export const SUBSCRIBE_PAYMENT_FORM_CARD_NUMBER =
  'subscribe.paymentForm.cardNumber';
export const SUBSCRIBE_PAYMENT_FORM_CARD_CVV = 'subscribe.paymentForm.cardCvv';
export const SUBSCRIBE_PAYMENT_FORM_CARD_EXPIRATION_MONTH =
  'subscribe.paymentForm.cardExpirationMonth';
export const SUBSCRIBE_PAYMENT_FORM_CARD_EXPIRATION_YEAR =
  'subscribe.paymentForm.cardExpirationYear';
export const SUBSCRIBE_PAYMENT_FORM_BILLING_ZIPCODE =
  'subscribe.paymentForm.billingZipcode';
export const SUBSCRIBE_PAYMENT_FORM_BILLING_COUNTRY =
  'subscribe.paymentForm.billingCountry';
export const SUBSCRIBE_PAYMENT_FORM_COUNTRY = 'subscribe.paymentForm.country';
export const SUBSCRIBE_PAYMENT_FORM_STATE = 'subscribe.paymentForm.state';
export const SUBSCRIBE_PAYMENT_FORM_BILLING_FORM =
  'subscribe.paymentForm.billingAddress';
export const SUBSCRIBE_PAYMENT_FORM_ADDRESS_ONE =
  'subscribe.paymentForm.address.one';
export const SUBSCRIBE_PAYMENT_FORM_ADDRESS_TWO =
  'subscribe.paymentForm.address.two';
export const SUBSCRIBE_PAYMENT_FORM_CITY = 'subscribe.paymentForm.city';

// no payment flow
export const SUBSCRIBE_WITHOUT_PAYMENT_ERROR =
  'subscribe.without.payment.error';
export const SUBSCRIBE_WITHOUT_PAYMENT_LOADING =
  'subscribe.without.payment.loading';
export const SUBSCRIBE_WITHOUT_PAYMENT_RETRY_BUTTON_LABEL =
  'subscribe.without.payment.retry.button.label';

// payment form field errors
export const SUBSCRIBE_PAYMENT_FORM_CARD_CVV_ERROR =
  'subscribe.paymentForm.card.cvv.error';
export const SUBSCRIBE_PAYMENT_FORM_CARD_EXPIRATION_ERROR =
  'subscribe.paymentForm.card.expiration.error';
export const SUBSCRIBE_PAYMENT_FORM_CARD_NUMBER_ERROR =
  'subscribe.paymentForm.card.number.error';
export const SUBSCRIBE_PAYMENT_FORM_CARD_ERROR =
  'subscribe.paymentForm.card.error';

// payment form errors
export const PAYMENT_DEFAULT_ERROR = 'subscription.payment.default.error.one';
export const EXISTING_SUBSCRIPTION_ERROR =
  'subscription.payment.existing.subscription';

// Test card numbers to trigger the below validation errors can be found here:
// https://docs.recurly.com/docs/test#section-test-credit-card-numbers
export const CARD_DECLINED = 'subscription.payment.card.declined'; // 4000-0000-0000-0002
export const AVS_CHECK_FAILED = 'subscription.payment.avs.check.failed'; // 4000-0000-0000-0010, 4000-0000-0000-0028, 4000-0000-0000-0036
export const TRANSACTION_DECLINED_1 =
  'subscription.payment.transaction.declined.one'; // 4000-0000-0000-0044, 4000-0000-0000-0085
export const TRANSACTION_DECLINED_2 =
  'subscription.payment.transaction.declined.two'; // 4000-0000-0000-0093
export const TRANSACTION_DECLINED_3 =
  'subscription.payment.transaction.declined.three'; // 4000-0000-0000-0341
export const DECLINED_EXPIRATION_DATE =
  'subscription.payment.declined.exp.date'; // 4000-0000-0000-0069, 4000-0000-0000-0226
export const INSUFFICIENT_FUNDS = 'subscription.payment.insufficient.funds'; // 4000-0000-0000-0077
export const DECLINED_BY_BANK = 'subscription.payment.declined.by.bank'; // 4000-0000-0000-0119
export const CARD_TYPE_NOT_ACCEPTED =
  'subscription.payment.card.type.not.accepted'; // 4000-0000-0000-0325
export const TIMEOUT_ERROR = 'subscription.payment.timeout.error'; // 4000-0000-0000-0309
export const DUPLICATE_TRANSACTION =
  'subscription.payment.duplicate.transaction'; // 4000-0000-0000-0317
export const INVALID_DATA = 'subscription.payment.invalid.data'; // 4000-0000-0000-0200
export const CVV_NOT_MATCH = 'subscription.payment.cvv.not.match'; // 4000-0000-0000-0101
export const CARD_NUMBER_INVALID = 'subscription.payment.card.num.invalid'; // 4000-0000-0000-0051\
export const LOST_CARD = 'subscription.payment.card.lost'; // 4000-0000-0000-9987
export const STOLEN_CARD = 'subscription.payment.card.stolen'; // 4000-0000-0000-9979
export const INCORRECT_CVC = 'subscription.payment.card.incorrect.cvc'; // 4000-0000-0000-0127
export const PROCESSING_ERROR = 'subscription.payment.card.processing.error'; // 4000-0000-0000-0119

export const TEMPORARY_HOLD = 'subscription.payment.card.temporary.hold';
export const FRAUD_VELOCITY_ERROR = 'subscription.payment.card.fraud.velocity';
export const TOO_MANY_ATTEMPTS = 'subscription.payment.too.many.attempts';
export const CARD_ISSUER_CARDHOLDER_DATA =
  'subscription.payment.call.issuer.cardholder';
export const DECLINED_SECURITY_CODE =
  'subscription.payment.declined.security.code';
export const TRANSACTION_DECLINED_EXCEPTION =
  'subscription.payment.declined.exception';
export const MISSING_REQUIRED_BILLING_INFO =
  'subscription.payment.missing.billing.info';
export const DECLINED_CARD_NUMBER = 'subscription.payment.declined.card.number';
export const GATEWAY_TOKEN_NOT_FOUND =
  'subscription.payment.gateway.token.not.found';
export const CARD_IS_EXPIRED = 'subscription.payment.card.expired';
export const EXCEEDS_DAILY_LIMIT = 'subscription.payment.exceeds.daily.limit';
export const INVALID_MERCHANT_TYPE =
  'subscription.payment.invalid.merchant.type';
export const INVALID_TRANSACTION = 'subscription.payment.invalid.transaction';
export const INVALID_ISSUER = 'subscription.payment.invalid.issuer';
export const PAYMENT_NOT_ACCEPTED = 'subscription.payment.not.accepted';
export const RESTRICTED_CARD = 'subscription.payment.restricted.card';
export const RESTRICTED_CARD_CHARGEBACK =
  'subscription.payment.restricted.card.chargeback';
export const CARD_NOT_ACTIVATED = 'subscription.payment.card.not.activated';
export const FRAUD_STOLEN_CARD = 'subscription.payment.stolen.card';
export const FRAUD_TOO_MANY_ATTEMPTS = 'subscription.payment.too.many.attempts';
export const FRAUD_ADVANCED_VERIFICATION =
  'subscription.payment.advanced.verification';
export const FRAUD_GENERIC = 'subscription.payment.fraud.generic';
export const ADDRESS_NOT_A_MATCH = 'subscription.payment.fraud.address';
export const FRAUD_RISK_CHECK = 'subscription.payment.fraud.risk.check';
export const FRAUD_MANUAL_DECISION =
  'subscription.payment.fraud.manual.decision';
export const PROCESSOR_UNAVAILABLE =
  'subscription.payment.processor.unavailable';
export const ISSUER_UNAVAILABLE = 'subscription.payment.issuer.unavailable';
export const GATEWAY_ERROR = 'subscription.payment.gateway.error';
export const TRY_AGAIN = 'subscription.payment.try.again';
export const CVV_REQUIRED = 'subscription.payment.cvv.required';
export const NO_GATEWAY = 'subscription.payment.no.gateway';

// subscribe payment form
export const SUBSCRIBE_PAYMENT_FORM_DEFAULT_ERROR =
  'subscribe.payment.form.default.error';
export const SUBSCRIBE_PAYMENT_FORM_SUCCESS_DIALOG =
  'subscribe.payment.form.success.dialog';

// renew payment form
export const RENEW_FORM_DEFAULT_ERROR = 'renew.form.default.error';
export const RENEW_FORM_SUCCESS_DIALOG = 'renew.form.success.dialog';

// billing information form
export const UPDATE_PAYMENT_FORM_DEFAULT_ERROR =
  'update.payment.form.default.error';
export const UPDATE_PAYMENT_FORM_SUCCESS_DIALOG =
  'update.payment.form.success.dialog';
export const UPDATE_PAYMENT_FORM_SUCCESS_HEADING =
  'update.payment.form.success.heading';

export const SUBSCRIPTION_UNAVAILABLE_TITLE = 'subscription.unavailable.title';
export const SUBSCRIPTION_UNAVAILABLE_DESCRIPTION =
  'subscription.unavailable.description';

export const PAYMENT_FORM_OR_ENTER_YOUR_CARD =
  'payment.form.or.enter.your.card';
export const PAYPAL_PAYMENT_FAILED_MESSAGE =
  'paypal.payment.failed.please.try.again';
export const PAYPAL_PAYMENT_ACTIVE_IN_ANOTHER_WINDOW =
  'paypal.is.active.in.another.window';
export const PAYPAL_PAYMENT_OPTIONS_INVALID =
  'paypal.payment.options.are.invalid';

// small upsell card
export const SUBSCRIBE_MONTHLY_LABEL_WITH_DAYS_TRIAL =
  'subscribe.monthly.label.with.days.trial';
export const SUBSCRIBE_MONTHLY_LABEL_NO_TRIAL =
  'subscribe.monthly.label.no.trial';
export const SUBSCRIBE_YEARLY_LABEL_WITH_DAYS_TRIAL =
  'subscribe.yearly.label.with.days.trial';
export const SUBSCRIBE_YEARLY_LABEL_NO_TRIAL =
  'subscribe.yearly.label.no.trial';
export const SMALL_UPSELL_CARD_TITLE = 'subscribe.small.upsell.card.title';

// used for subscription sku driven promotions
export const SUBSCRIBE_FREE_TRIAL_PERIOD_MONTH =
  'subscribe.free.trial.period.month';
export const SUBSCRIBE_FREE_TRIAL_PERIOD_MONTHS =
  'subscribe.free.trial.period.months';
export const SUBSCRIBE_FREE_TRIAL_PERIOD_DAY =
  'subscribe.free.trial.period.day';
export const SUBSCRIBE_FREE_TRIAL_PERIOD_DAYS =
  'subscribe.free.trial.period.days';
export const SUBSCRIBE_FREE_TRIAL_WITH_PRICE_PER_PERIOD =
  'subscribe.free.trial.with.price.per.period';
