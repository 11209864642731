export const ERROR_MESSAGE = 'player.errorMessage';
export const AD_BLOCKER_ERROR_MESSAGE = 'player.ad.blocker.message';
export const AD_BLOCKER_ERROR_MESSAGE_ENABLED =
  'player.ad.blocker.message.enabled';
export const MEDIA_AD_TITLE = 'player.preroll.title';
export const MEDIA_AD_SUBTITLE = 'player.preroll.subtitle';
export const POP_OUT_MESSAGE = 'player.popOut.message';
export const POP_OUT_DIALOG_MESSAGE_V2 = 'player.popOutDialog.message.v2';
export const POP_OUT_DIALOG_MESSAGE_LINK = 'player.popOutDialog.message.link';
export const POP_OUT_DIALOG_SPEED_CONTROL_TITLE =
  'player.popOutDialog.speedControl.title';
export const LIVE = 'live';
