export const ENABLE_PLAYBACK_SPEED_CONTROL =
  'web.playback.speed.control.enabled';
export const IHEARTRADIO_ALBUM_ART_ENABLED = 'iHeartRadio.albumArt.enabled';
export const HOWLER_ENABLED = 'player.howler.enabled';
export const FORCE_HTTPS_STREAMS = 'player.forceHttpsStreams.enabled';
export const REJECTED_STREAMS_REASON_BLOCKLIST =
  'player.rejectedStreams.reasonBlocklist';

// Listen Time Report Configs
// Indirectly configured by `api.reportStatus.interval` Partner Setting
export const LISTEN_TIME_REPORT_INTERVAL_SECONDS = 'report.listen.interval';
// Initial interval is an optional configuration that allows the listen time report to use an initial interval for an
// optional number of repeated times.
// Example:
//  10 second intervals, 10 times.
//  Then it will switch to LISTEN_TIME_REPORT_INTERVAL_SECONDS or a hard-coded fallback.
export const LISTEN_TIME_REPORT_INITIAL_INTERVAL_SECONDS =
  'report.listen.initial.interval.seconds';
export const LISTEN_TIME_REPORT_INITIAL_INTERVAL_REPEAT =
  'report.listen.initial.interval.repeat';

// Specifies the max number of streams web-tuner can preload concurrently.
export const WEB_TUNER_MAX_PRELOAD_STREAMS = 'player.maxPreloadStreams';

// Configures web-tuner to allow caching of Tune & NP API stream data on page load
export const WEB_TUNER_PRE_TUNE_ENABLED = 'player.preload.enabled';
